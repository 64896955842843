import Vuex from 'vuex';

export default new Vuex.Store({
    state: {
        // Аккаунт пользователя
        userAccount: false,
        // Пользователь вошел в систему?
        isLoggedIn: false,
        // Пользователь администратор?
        isAdmin: false,
        // Разрешить регистрацию
        allowregister: false,
        // разрешить повторную прохождение тестов
        allowretest: false,
        allowtest: false,
        // Разрешить просмотр результатов
        allowresults: false,
        // Заголовок авторизации
        authHeader: {},
        // Информация о пользователе
        userInfo: {},
        // Текущий тест
        // Может быть для редактирования или прохождения тестов
        currentQuiz: {},
        // Текущий вопрос, для прохождения теста
        currentQuestion: false,
        // Отладка
        hostDebug:'',
        // hostDebug: 'http://localhost:8000',
    },
    mutations: {
        /**
         * Установить разрешение на регистрацию
         */
        setAllowRegister(state, val) {
            state.allowregister = val;
        },

        /**
         * Установить разрешение на просмотр результатов
         */
        setAllowResults(state, val) {
            state.allowresults = val;
        },

        // Установить статус входа в систему
        setLoggedIn(state, val) {
            state.isLoggedIn = val;
        },

        // заполняем данные о пользователе
        setUserInfo(state, val) {
            state.userInfo = val;
        },
        
        // разрешить повторную прохождение тестов
        setAllowRetest(state, val) {
            state.allowretest = val;
        },

        // разрешить повторную прохождение тестов
        setAllowTest(state, val) {
            state.allowtest = val;
        },

        // Установить статус администратора
        setAdmin(state, val) {
            state.isAdmin = val;
        },

        // Сохранить заголовок авторизации
        setAuthHeader(state, val) {
            state.authHeader = val;
        },

        // Установить текущий тест
        setCurrentQuiz(state, val) {
            state.currentQuiz = val;
        },
    },
    actions: {},
    modules: {},
    getters: {
        // Получить значение hostDebug
        hostDebug: (state) => state.hostDebug,

        // Получить статус входа в систему
        isLoggedIn: (state) => state.isLoggedIn,

        // Получить статус администратора
        isAdmin: (state) => state.isAdmin,

        // Получить заголовок авторизации
        authHeader: (state) => state.authHeader,

        // Получить текущий тест
        currentQuiz: (state) => state.currentQuiz,

        // Получить статус разрешения на регистрацию
        allowregister: (state) => state.allowregister,

        // Получить статус разрешения на просмотр результатов
        allowresults: (state) => state.allowresults,

        //разрешить повторную прохождение тестов
        allowretest: (state) => state.allowretest,

        allowtest: (state) => state.allowtest,
    }
});
