<template>
  <div class="home">
    <LoginBar @login-event="login" @logout-event="logout" ref="loginbar"></LoginBar>
    
    <header class="hero-section text-center">
      <div class="container">
          <h1 class="mb-4">🎓 Всероссийская онлайн-олимпиада</h1>
          <h2>Военно-космическая академия имени А.Ф. Можайского</h2>
          <h3>Факультет 5 - сбора и обработки информации</h3>
          <h4>Кафедра 52 - технологий и средств геофизического обеспечения</h4>
          
          <div class="mt-4">
              <button v-if="!allowtest" class="cta-button"  @click="registerwindow">Регистрация открыта до 18 декабря</button>
              <button v-if="allowtest && !isLoggedIn" class="cta-button"  @click="loginwindow">🔔 Олимпиада началась! Для участия необходимо войти в систему</button>

              <div class="row" v-if="allowtest&&isLoggedIn">
                <h2>Доступные тесты</h2>
                  <div  
                    v-for="(item,index) in quizList"
                    :key="index"
                    class="card card-shadow me-3 mt-3 col"
                    href="/quiz/start/{{item.id}}" >
                    <div class="card-header">
                      <h4>{{item.title}}</h4>
                    </div>
                    <div class="card-footer">
                      <button class="btn btn-primary" @click="startQuiz(item.id)">Пройти тест</button>
                    </div>
                  </div>
                
              </div>

          </div>
      </div>
  </header>

<main class="container py-5">
    <div class="alert alert-danger p-4 rounded-lg">
        <h3>‼️ Внимание!</h3>
        <p>Пользователи, прошедшие регистрацию до <b>13 декабря 2024</b> - просьба пройти регистрацию заново.</p>
    </div>
    <!-- Timeline Section -->
    <section class="timeline-container">
        <h2 class="text-center mb-4">📅 Важные даты</h2>
        <div class="d-flex justify-content-around text-center">
            <div>
                <h4>Регистрация</h4>
                <p>c 10:00 МСК 18 декабря 2024<br/>по 14:00 МСК 19 декабря 2024</p>
            </div>
            <div>
                <h4>Олимпиада</h4>
                <p>19 декабря 2024<br/>14:10 - 24:00 МСК</p>
            </div>
            <div>
                <h4>Результаты</h4>
                <p>26 декабря 2024<br/>12:00 МСК</p>
            </div>
        </div>
    </section>

    <!-- Information Cards -->
    <div class="row">
        <div class="col-md-4">
            <div class="feature-card">
                <h3>📝 Формат олимпиады</h3>
                <p>Тестовые задания включают:</p>
                <ul>
                    <li>Вопросы с развернутыми ответами</li>
                    <li>Задания с однозначным выбором</li>
                    <li>Вопросы с множественным выбором</li>
                </ul>
            </div>
        </div>
        <div class="col-md-4">
            <div class="feature-card">
                <h3>🎯 Система оценивания</h3>
                <p>Критерии оценки:</p>
                <ul>
                    <li>Правильность ответов</li>
                    <li>Скорость выполнения</li>
                    <li>Полнота решения</li>
                </ul>
            </div>
        </div>
        <div class="col-md-4">
            <div class="feature-card">
                <h3>🏆 Награждение</h3>
                <p>Победители получают:</p>
                <ul>
                    <li>Дипломы победителей</li>
                    <li>Преимущество при поступлении</li>
                </ul>
            </div>
        </div>
    </div>


    <!-- Status Messages -->
    <div v-if="!allowtest" class="alert alert-info mt-5 p-4 rounded-lg">
        <h3>⏳ Олимпиада еще не началась</h3>
        <p v-if="allowregister">Сейчас доступна <a href="#" @click="registerwindow" class="text-primary">регистрация</a> участников</p>
    </div>

    <div v-if="allowtest && !isLoggedIn" class="alert alert-info mt-5 p-4 rounded-lg">
        <h3>🔔 Олимпиада началась!</h3>
        <p>Для участия необходимо войти в систему</p>
    </div>

    
    <!-- About Academy Section -->
    <section class="mt-5">
        <h2 class="text-center mb-4">🚀 О нашей академии</h2>
        <div class="row">
            <div class="col-md-6">
                <div class="feature-card">
                    <h3>Видео о ВКА</h3>
                    
                    <div class="ratio ratio-16x9 mb-2">
                      <iframe width="720" height="405" src="https://rutube.ru/play/embed/775fdba2fb98692bc877bfee368bf6bc/" frameBorder="0" allow="clipboard-write;" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                    </div>
                    <div class="ratio ratio-16x9 mb-2">
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/LZEXSoTFxGs?si=Ks9ZzmGUo1fqDH2d" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <!--div class="ratio ratio-16x9 ">
                      <iframe width="560" height="315" src="//www.1tv.ru/embed/206254:12" allow="encrypted-media; picture-in-picture" frameborder="0" allowfullscreen></iframe>
                    </!div-->
                    
                </div>
            </div>
            <div class="col-md-6">
                <div class="feature-card">
                    <h3>52 кафедра</h3>
                    <div class="ratio ratio-16x9 mb-2">
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/iF8jCdCicms?si=iCbOOh0jcAeCgBBi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div class="ratio ratio-16x9 mb-2">
                        <a href="https://vka.mil.ru/video/302841?ysclid=m47bykduo079074228"><img src="/img/video1.jpg" alt="Academy photo 2" class="img-fluid rounded"/></a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Test Section (Conditional Rendering) -->
    <section v-if="allowtest && isLoggedIn" class="mt-5">
        <h2 class="text-center mb-4">📋 Доступные тесты</h2>
        <div class="row">
            <div v-for="(item, index) in quizList" :key="index" class="col-md-4">
                <div class="feature-card">
                    <h4>{{item.title}}</h4>
                    <button class="cta-button w-100" @click="startQuiz(item.id)">
                        Начать тест
                    </button>
                </div>
            </div>
        </div>
    </section>



    <section class="mt-5">
        <h2 class="text-center mb-4">🚀 Абитуриенту</h2>
        <div class="row">
            <div class="col-md-12">
                <div class="feature-card">
                  <img src="/img/page2.png" alt="правила поступления" class="img-fluid rounded"/>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
              <div class="feature-card">
                <img src="/img/page.png" alt="правила поступления" class="img-fluid rounded"/>
              </div>
          </div>
      </div>
    </section>

</main>

<footer class="mt-5 py-4 bg-white">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h4>Контакты</h4>
                <p>Email: <a href="mailto:info@meteoquiz.ru">info@meteoquiz.ru</a></p>
                <p>Адрес: г. Санкт-Петербург, ул. Ждановская, д. 13</p>
            </div>
            <div class="col-md-6 text-md-end">
                <p>© 2024 <a href="https://vka.mil.ru/">ВКА им.А.Ф.Можайского</a></p>
                <p>Все права защищены</p>
            </div>
        </div>
    </div>
</footer>
    
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import LoginBar from '../components/LoginBar.vue'

export default {
  name: "HomePage",
  data(){
    return {
      freeSpace: 0,
      quizList:[]
    }
  },
  components: {
    LoginBar
  },
  mounted() {
    this.loadQuiz();
  },
  methods:{
    ...mapMutations([
        // 'setActivePool',
        // 'setPools',
      ]),

    /**
     * Стартуем тест, но сначала проверяем залогинен ли пользователь
     */
    startQuiz(quiz_id){
      if (!this.isLoggedIn){
        this.$swal( { 
            title: 'Доступ запрещен',
            icon: 'error',
            text: "Для прохождения теста необходимо зарегистрироваться и войти в систему.",
          } );
        return;
      }
      this.$router.push('/quiz/'+quiz_id)
    },

    /**
     * Загружаем данные об операциях
     */
    async loadQuiz(){
      this.ajaxBlock = true;
      this.datatable = [];
      const response = await fetch(this.hostDebug+"/api/quiz", {
                headers: { "Content-Type": "application/json",
                          "Accept" : "application/json",
                          ...this.authHeader },
                method: "GET"
              });
      this.ajaxBlock=false;
      response.json().then( (data)=>{
        if ( data ){
          this.quizList = data;
        }
      } )
    },

    login(){
      console.log("login event");
    },

    logout(){
      console.log("logout event");
    },

    registerwindow(){
      // console.log("register event");
      this.$refs.loginbar.openRegisterWindow();
    },

    loginwindow(){
      this.$refs.loginbar.openLoginWindow();
    }
  },
  computed:{
    ...mapGetters([
      'hostDebug',
      'isLoggedIn',
      'isAdmin',
      'authHeader',
      'allowtest',
      'allowregister'
    ]),
   
  },
};
</script>
